@charset "UTF-8";
/* Colors Neuhaus */
/* Fonts Neuhaus */
html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 3rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2rem; } }

h3,
.h3 {
  font-size: 1.25rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, .stocknotification label,
.stocknotification-confirm label, .font-heading {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (min-width: 1440px) and (max-width: 1679.98px) {
  .pull-left-xxl {
    float: left; }
  .pull-right-xxl {
    float: right; } }

@media (min-width: 1680px) {
  .pull-left-xxxl {
    float: left; }
  .pull-right-xxxl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-xl-down {
    display: none !important; } }

@media (max-width: 1679.98px) {
  .hidden-xxl-down {
    display: none !important; } }

.hidden-xxxl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #19b200;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #19b200;
  border-radius: 0.69em;
  background-color: #19b200;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #821e91), color-stop(25%, #821e91), color-stop(25%, #edd134), color-stop(50%, yellow), color-stop(50%, #edd134), color-stop(50%, #59ba00), color-stop(76%, #59ba00), color-stop(76%, #111), to(#111)), -webkit-gradient(linear, left bottom, left top, color-stop(0, #0e5cd1), color-stop(50%, #0e5cd1), color-stop(50%, #e20b0b), to(#e20b0b));
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg); }

.box-content-title {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    .box-content-title {
      font-size: 1.5rem; } }

.box-content .icon-box {
  width: 30px;
  height: 30px; }

.box-content .item-detail {
  background-color: #efefef;
  padding: 3rem 0;
  position: relative; }
  .box-content .item-detail .praline-content {
    padding: 1.5rem 0;
    margin: 1.5rem 0;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd; }
  .box-content .item-detail::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-bottom: solid 20px #efefef;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent; }

.box-content > .carousel .glider-track {
  margin: 0 auto 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.box-content .js-praline-item {
  cursor: pointer; }

.box-content .cross-section-carousel {
  width: 445px;
  max-width: 100%; }
  .box-content .cross-section-carousel .glider {
    scrollbar-width: none; }
    .box-content .cross-section-carousel .glider::-webkit-scrollbar {
      width: 0;
      height: 0; }

.box-content .cross-section-carousel-prl .glider {
  scrollbar-width: none; }
  .box-content .cross-section-carousel-prl .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

@media (max-width: 1199.98px) {
  .box-content .cross-section-carousel-prl .carousel-control {
    width: 20px; } }

.box-content .cross-section-carousel-prl a.glider-slide {
  margin-right: 0.5rem;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.box-content .other-boxes-carousel {
  padding: 0 24px; }
  @media (min-width: 769px) {
    .box-content .other-boxes-carousel {
      padding: 0 40px;
      margin: 0 -40px; } }

.box-content .praline-img {
  max-width: 80%; }
  @media (min-width: 769px) {
    .box-content .praline-img {
      max-width: 100%; } }

/* Colors Neuhaus */
/* Fonts Neuhaus */
.carousel .carousel-control {
  position: absolute;
  z-index: 0;
  width: 30px;
  height: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: calc(50% - 20px); }
  @media (min-width: 769px) {
    .carousel .carousel-control {
      width: 40px;
      height: 40px; } }
  .carousel .carousel-control, .carousel .carousel-control.disabled {
    opacity: 1; }
  .carousel .carousel-control, .carousel .carousel-control:hover {
    padding: 0; }

.carousel .pd-carousel-indicators {
  position: static; }

.carousel-inner {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem; }
  @media (min-width: 769px) {
    .carousel-inner {
      padding-bottom: 2rem; } }

.carousel .glider img {
  opacity: 0; }

.carousel .glider .glider-track img {
  opacity: 1; }

.pd-carousel-container {
  margin: 0 auto;
  width: calc(100% - 80px); }
  .pd-carousel-container .carousel-control-prev {
    left: -40px; }
  .pd-carousel-container .carousel-control-next {
    right: -40px; }

@media (max-width: 768.98px) {
  .btn-notify {
    width: 100%; } }

.stocknotification,
.stocknotification-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(45, 45, 45, 0.75);
  z-index: 20; }
  .stocknotification label,
  .stocknotification-confirm label {
    color: #7c655a; }

.AddToCart-overlay-container {
  background-color: rgba(45, 45, 45, 0.75);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; }
  .AddToCart-overlay-container .product-image {
    max-width: 100px; }

.pdp-recom .recommendations {
  margin: 2rem 0; }
  @media (min-width: 544px) {
    .pdp-recom .recommendations {
      margin: 3rem 0; } }

.pdp-recom .einstein-title {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    .pdp-recom .einstein-title {
      font-size: 1.5rem; } }

.product-detail .attributes .swatch-circle {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-right: 0.313em;
  position: relative; }
  .product-detail .attributes .swatch-circle.color-value[data-selected=true]::after {
    color: #000;
    content: '\f058';
    display: table-caption;
    font-family: 'FontAwesome';
    font-size: 1.625em;
    left: 0.295em;
    position: absolute; }
  .product-detail .attributes .swatch-circle.color-value.selected::after {
    background: #fff;
    border-radius: 50%;
    color: #000;
    content: '\f058';
    display: table-caption;
    font-family: 'FontAwesome';
    font-size: 1.625em;
    height: 0.75em;
    left: 0.31em;
    line-height: 0.8em;
    position: absolute;
    top: 0.35em;
    width: 0.8em; }
  .product-detail .attributes .swatch-circle i.fa-times-circle {
    background: #fff;
    border-radius: 50%;
    height: 0.75em;
    line-height: 0.8em;
    width: 0.8em; }

.product-detail [disabled] .swatch-circle,
.product-detail .swatch-circle.unselectable {
  cursor: not-allowed; }
  .product-detail [disabled] .swatch-circle.color-value.selected::after,
  .product-detail .swatch-circle.unselectable.color-value.selected::after {
    background-color: #495057; }

label.color ~ a:hover {
  text-decoration: none; }

.description-and-detail {
  margin-top: 2em; }
  @media (max-width: 543.98px) {
    .description-and-detail {
      margin-top: 0; }
      .description-and-detail .title {
        font-size: 1.5rem;
        margin-bottom: 0; }
      .description-and-detail > div:last-child {
        margin-bottom: 1em; } }
  @media (min-width: 544px) {
    .description-and-detail .description {
      margin-bottom: 2em; } }
  @media (max-width: 543.98px) {
    .description-and-detail .content {
      margin-top: 1em; } }

.social-container {
  padding-top: 0.9375em;
  text-align: center; }
  .social-container .social-icons {
    text-decoration: none;
    font-size: 1.875em;
    list-style-type: none;
    padding: 0;
    color: #444; }
    @media (max-width: 991.98px) {
      .social-container .social-icons {
        font-size: 1.5625em; } }
    .social-container .social-icons i,
    .social-container .social-icons a {
      color: #444;
      padding-right: 0.2em; }

label.availability {
  padding-right: 0.3125em; }

@media (min-width: 544px) {
  .product-number-rating {
    border-bottom: 1px solid #ccc; } }

@media (min-width: 769px) {
  .product-number-rating {
    border-top: 1px solid #ccc; } }

@media (min-width: 544px) {
  .product-number-rating .ratings {
    margin-top: 0.625em; } }

@media (max-width: 543.98px) {
  .product-number-rating .ratings {
    background-color: #fff;
    margin-top: -1.875em;
    /* 30/16 */
    margin-right: -0.9375em;
    /* 15/16 */
    padding: 0.1875em;
    /* 3/16 */ } }

.product-number {
  color: #ccc;
  font-size: 0.875em;
  padding-bottom: 0.938em;
  padding-top: 0.938em; }

.promotions {
  font-weight: normal;
  text-align: center;
  height: 85%; }
  .promotions div.collapsible-xl button.callout {
    font-size: 1.25em;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    color: #b14350; }
    @media (min-width: 544px) {
      .promotions div.collapsible-xl button.callout {
        font-size: 1.375em; } }
    .promotions div.collapsible-xl button.callout::after {
      color: #000; }

.product-options {
  margin-top: 1.25em; }

.size-chart .size-chart-collapsible {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0, 1);
          transition-timing-function: cubic-bezier(0, 1, 0, 1); }
  .size-chart .size-chart-collapsible.active {
    max-height: 100%;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out; }

.color-attribute {
  border: none;
  padding: 0;
  background: none; }

.non-input-label {
  display: block;
  margin-bottom: 0.5rem; }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0.938em; }
    .product-grid .col-6 {
      padding-left: 5px;
      padding-right: 5px; } }

/* CUSTOM CSS */
.product-detail .price {
  text-align: left;
  font-size: 1.5rem;
  color: #cf7c58; }
  .product-detail .price .value {
    font-weight: 400; }

.product-detail .product-tile .price .value,
.product-detail .product-tile .pdp-link a {
  font-size: 1rem; }

.product-detail .product-tile .image-container {
  margin-bottom: 1rem; }
  .product-detail .product-tile .image-container a .tile-image {
    min-height: auto; }

.product-info-container {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 544px) {
    .product-info-container {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd; } }
  .product-info-container .delivery-time {
    padding: 1rem 0; }
    @media (min-width: 544px) {
      .product-info-container .delivery-time {
        border-left: 1px solid #ddd;
        padding-left: 1.5rem;
        margin-left: 1.5rem; } }
  .product-info-container .availability {
    padding: 1rem 0;
    margin: 0; }
  .product-info-container .price-and-availability {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    @media (min-width: 544px) {
      .product-info-container .price-and-availability {
        border: none; } }
    .product-info-container .price-and-availability .prices {
      border-right: 1px solid #ddd;
      padding: 1rem 1.5rem 1rem 0;
      margin-right: 1.5rem; }

.availability-msg {
  margin-bottom: 0; }
  .availability-msg .in-stock {
    color: #19b200;
    position: relative;
    padding-left: 18px; }
    .availability-msg .in-stock::before {
      content: "";
      background-color: #19b200;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      position: absolute;
      top: calc(50% - 5px);
      left: 0; }
  .availability-msg .out-of-stock {
    color: #b14350; }

.btn-product-collapsed {
  border-top: 1px solid #ddd;
  padding: 1.5rem 0; }

.product-links {
  border-top: 1px solid #ddd; }

.pdp-img-subslider .subslider-item {
  border: 1px solid #ddd;
  margin: 1rem; }
  .pdp-img-subslider .subslider-item.selected {
    border-color: #a5a5a5; }

.pdp-img-subslider .glider {
  scrollbar-width: none; }
  .pdp-img-subslider .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

.pdp-main-image {
  max-width: 575px; }
  .pdp-main-image .glider {
    scrollbar-width: none; }
    .pdp-main-image .glider::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .pdp-main-image:hover {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }

.zoom-glider .image-wrapper,
.mainslider-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .zoom-glider .image-wrapper .personalisation-txt-overlay,
  .mainslider-item .personalisation-txt-overlay {
    position: absolute;
    padding: 0 1rem;
    max-width: var(--persPosWidth);
    top: var(--persPosTop);
    color: #cf7c58;
    font-size: 100%; }
    @media (min-width: 544px) {
      .zoom-glider .image-wrapper .personalisation-txt-overlay,
      .mainslider-item .personalisation-txt-overlay {
        font-size: 210%; } }
    @media (min-width: 769px) {
      .zoom-glider .image-wrapper .personalisation-txt-overlay,
      .mainslider-item .personalisation-txt-overlay {
        font-size: 100%; } }
    @media (min-width: 992px) {
      .zoom-glider .image-wrapper .personalisation-txt-overlay,
      .mainslider-item .personalisation-txt-overlay {
        font-size: 210%; } }

.zoom-glider .image-wrapper .personalisation-txt-overlay {
  max-width: 60%; }

.pdp-img-subslider {
  max-width: 475px; }

.js-praline-matcher .glider {
  scrollbar-width: none; }
  .js-praline-matcher .glider::-webkit-scrollbar {
    width: 0;
    height: 0; }

.pdp-image-zoom {
  position: fixed;
  background: #fff;
  width: 100vw;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  padding-top: 40px;
  padding-bottom: 40px; }
  .pdp-image-zoom .glider {
    scrollbar-width: none; }
    .pdp-image-zoom .glider::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .pdp-image-zoom .icon-cross {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .pdp-image-zoom .carousel {
    height: calc(100% - 4rem); }
    .pdp-image-zoom .carousel .glider,
    .pdp-image-zoom .carousel .glider-track,
    .pdp-image-zoom .carousel .glider-slide,
    .pdp-image-zoom .carousel .image-wrapper,
    .pdp-image-zoom .carousel img {
      height: 100%; }
    .pdp-image-zoom .carousel img {
      margin: 0 auto;
      -o-object-fit: contain;
         object-fit: contain; }

ul.usp-items {
  list-style: none; }

ul.usp-items li::before {
  content: "✓";
  color: #19b200;
  font-weight: bold; }
